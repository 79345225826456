import {
    CalendarClockIcon,
    CompassIcon,
    EyeIcon,
    EyeOffIcon,
    FacebookIcon,
    FactoryIcon,
    GlobeIcon,
    InstagramIcon,
    LanguagesIcon,
    LinkIcon,
    LinkedinIcon,
    RulerIcon,
    TwitterIcon,
    UsersRoundIcon,
} from 'lucide-react';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { classnames } from '@nicoknoll/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

import Anchor from '../../../components/Anchor.tsx';
import Avatar from '../../../components/Avatar.tsx';
import EditBar from '../../../components/EditBar.tsx';
import Image from '../../../components/Image.tsx';
import LinkButton from '../../../components/LinkButton.tsx';
import Tooltip from '../../../components/Tooltip.tsx';
import { EventSession, Job, LogEventType, OrganizationProfile, User } from '../../../data/models.ts';
import {
    conversationCreateMutation,
    conversationListQuery,
    eventSessionListQuery,
    organizationProfileJobListQuery,
    organizationProfileListQuery,
    organizationProfileUserListQuery,
    userDetailQuery,
} from '../../../data/queries.ts';
import { getOrCreateFileId } from '../../../utils/files.ts';
import { isOrganizationUser, isRegularUser } from '../../../utils/loaders.ts';
import { formatDate } from '../../../utils/time.ts';
import url from '../../../utils/url';
import useLogEvent, { useLogEventOnMount } from '../../../utils/useLogEvent.ts';
import { ADMIN_ORGANIZATION_PROFILE_DETAIL_PATH } from '../../admin/organization-profiles/routes.tsx';
import { ADMIN_LOGIN_PATH } from '../../auth/routes.tsx';
import BaseConversationFormDialog from '../components/BaseConversationFormDialog.tsx';
import InfoBox from '../components/InfoBox.tsx';
import JobCard from '../components/JobCard.tsx';
import { SaveOrganizationButton } from '../components/SaveOrganizationButton.tsx';
import SessionCard from '../components/SessionCard.tsx';
import { SITE_CONVERSATION_DETAIL_PATH, SITE_ORGANIZATION_DETAIL_PATH } from '../routes.tsx';
import { getBackgroundColor, siteUrl, useEventId } from '../utils.ts';

const fixUrl = (url: string) => {
    if (!url) return '';
    if (url.startsWith('http')) return url;
    if (url.startsWith('www')) return `https://${url}`;
    return `https://www.${url}`;
};

const getUsername = (url: string) => {
    if (!url) return '';

    let fixedUrl = fixUrl(url);
    if (fixedUrl.endsWith('/')) fixedUrl = fixedUrl.slice(0, -1);
    if (fixedUrl.includes('?')) fixedUrl = fixedUrl.split('?')[0];
    if (fixedUrl.startsWith('http://')) fixedUrl = fixedUrl.slice(7);
    if (fixedUrl.startsWith('https://')) fixedUrl = fixedUrl.slice(8);
    if (fixedUrl.startsWith('www.')) fixedUrl = fixedUrl.slice(4);

    if (fixedUrl.includes('linkedin.com')) {
        // linkedin.com/company/acme/about/?res=123
        const urlParts = fixedUrl.split('/');
        const companyIndex = urlParts.indexOf('company');
        if (companyIndex !== -1 && urlParts.length > companyIndex + 1) {
            return urlParts[companyIndex + 1];
        }
    }

    const urlParts = fixedUrl.split('/');
    return decodeURIComponent(urlParts[urlParts.length - 1]);
};

const getDomain = (url: string) => {
    if (!url) return '';

    const fixedUrl = fixUrl(url);
    const parsedUrl = new URL(fixedUrl);
    return decodeURIComponent(parsedUrl.hostname.replace('www.', ''));
};

export const SendMessageFormDialog = (props: any) => {
    const eventId = useEventId();

    const navigate = useNavigate();
    const { mutateAsync: createConversation } = useMutation(conversationCreateMutation());

    const [searchParams] = useSearchParams();

    const { data: viewer } = useQuery(userDetailQuery());

    const receiverId = searchParams.get('receiver') || '';
    const { data: receiver } = useQuery({ ...userDetailQuery(receiverId), enabled: Boolean(receiverId) });

    const createLogEvent = useLogEvent();

    const handleSubmit = async (values: any) => {
        const attachmentId = await getOrCreateFileId(
            values.attachment,
            isOrganizationUser(viewer!) ? { organizationId: viewer?.organizationId } : { userId: viewer?.id }
        );

        const conversation = await createConversation({
            data: {
                receiverId: receiver!.id,
                eventId,
                ...values,
                attachmentId,
            },
        });
        void createLogEvent(LogEventType.CONVERSATION_STARTED, { targetId: conversation.id, receiverId: receiver!.id });
        void createLogEvent(LogEventType.CONVERSATION_MESSAGE_SENT, {
            targetId: conversation.id,
            hasText: !!values.text,
            hasAttachment: !!values.attachment,
        });
        return navigate(siteUrl(SITE_CONVERSATION_DETAIL_PATH, { eventId, conversationId: conversation.id }));
    };

    return <BaseConversationFormDialog receiver={receiver} onSubmit={handleSubmit} {...props} />;
};

const SendMessageButton = ({
    receiver,
    className,
    ...props
}: React.ComponentPropsWithRef<'button'> & { receiver: User }) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();
    const { data: viewer } = useQuery(userDetailQuery());
    const receiverId = receiver?.id;
    const organizationId = receiver.organizationId;

    const { data: organizationProfileUsers } = useQuery(organizationProfileUserListQuery({ eventId, organizationId }));
    const organizationProfileUser = organizationProfileUsers?.find((profileUser) => profileUser?.userId === receiverId);
    const canReceiveMessages = receiverId !== viewer?.id && organizationProfileUser?.canReceiveMessages;

    const { data: conversations, isLoading } = useQuery({
        ...conversationListQuery({ userId: receiverId, isParticipant: true }),
        enabled: canReceiveMessages,
    });
    const conversation = conversations?.[0];

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const action = searchParams.get('action');

    const showSendMessageForm = action === 'message' && searchParams.get('receiver') === receiverId;

    if (!isRegularUser(viewer!)) {
        return null;
    }

    const button = (
        <LinkButton
            variant={conversation || isLoading ? 'outline' : 'primary'}
            asChild
            className={classnames('text-base min-h-9 px-4', !canReceiveMessages && 'cursor-not-allowed')}
            loading={isLoading ? true : undefined}
            disabled={!canReceiveMessages}
            onClick={(e) => {
                if (!viewer) {
                    e.preventDefault();
                    toast.error(t('organizationDetailPage.sendMessageNotAuthenticatedError'));
                    navigate(`${url(ADMIN_LOGIN_PATH)}?next=${location.pathname}`);
                    return;
                }
            }}
            to={
                conversation
                    ? siteUrl(SITE_CONVERSATION_DETAIL_PATH, { eventId, conversationId: conversation.id })
                    : siteUrl(SITE_ORGANIZATION_DETAIL_PATH, { organizationId, eventId }) +
                      `?action=message&receiver=${receiverId}`
            }
        >
            {conversation
                ? t('organizationDetailPage.gotoConversationButton')
                : t('organizationDetailPage.sendMessageButton')}
        </LinkButton>
    );

    return (
        <>
            {!canReceiveMessages ? (
                <Tooltip>
                    <Tooltip.Trigger>{button}</Tooltip.Trigger>
                    <Tooltip.Content>{t('organizationDetailPage.sendMessageButtonDisabled')}</Tooltip.Content>
                </Tooltip>
            ) : (
                button
            )}

            {showSendMessageForm && (
                <SendMessageFormDialog
                    receiver={receiver}
                    onIsOpenChange={() => navigate(siteUrl(SITE_ORGANIZATION_DETAIL_PATH, { organizationId, eventId }))}
                    onCancel={() => navigate(siteUrl(SITE_ORGANIZATION_DETAIL_PATH, { organizationId, eventId }))}
                />
            )}
        </>
    );
};

const ContactPersonItem = ({ contactPerson }: { contactPerson: User }) => {
    return (
        <div className="flex flex-col gap-6 justify-between items-center flex-1">
            <div className="flex flex-col gap-6 items-center">
                <Avatar user={contactPerson} className="w-32 h-32" />

                <div className="flex flex-col items-center text-center">
                    <span className="font-medium text-lg">
                        {contactPerson.firstName} {contactPerson.lastName}
                    </span>
                    <span className="text-base-500">{contactPerson.position}</span>
                </div>
            </div>
            <div>
                <SendMessageButton receiver={contactPerson} />
            </div>
        </div>
    );
};

const OrganizationDetailPage = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    const { t } = useTranslation('site');

    const eventId = useEventId();

    const { organizationId } = useParams();

    const { data: organizationProfilesData } = useQuery(organizationProfileListQuery({ eventId, organizationId }));
    const organizationProfile = organizationProfilesData?.[0] || ({} as OrganizationProfile);

    const { data: contactPersonsData } = useQuery(organizationProfileUserListQuery({ eventId, organizationId }));
    const contactPersons: User[] = contactPersonsData?.map((profileUser) => profileUser?.user as User) || [];

    const { data: profileJobsData } = useQuery(organizationProfileJobListQuery({ eventId, organizationId }));
    const jobs: Job[] = profileJobsData?.map((profileJob) => profileJob?.job as Job) || [];

    const { data: sessions } = useQuery(eventSessionListQuery({ eventId, organizationId }));

    const { data: viewer } = useQuery(userDetailQuery());
    const showEditBar = viewer?.organizationId === organizationId;

    const bannerWrapperRef = React.createRef<HTMLDivElement>();
    useEffect(() => {
        const magicBannerBackground = async () => {
            if (!bannerWrapperRef.current || !organizationProfile?.banner?.url) return;
            const colors = await getBackgroundColor(organizationProfile?.banner?.url!);
            if (!colors) return;
            bannerWrapperRef.current.style.background = `linear-gradient(180deg, ${colors.leftColor} 0%, ${colors.rightColor} 100%)`;
        };
        magicBannerBackground();
    }, [organizationProfile?.banner?.url]);

    useLogEventOnMount(LogEventType.ORGANIZATION_VIEW, { targetId: organizationId });

    return (
        <>
            {showEditBar && (
                <EditBar>
                    <div>
                        {organizationProfile?.isPublic ? (
                            <span className="flex items-center gap-2">
                                <EyeIcon />
                                <Trans t={t} i18nKey="organizationDetailPage.editBar.organizationIsPublic" />
                            </span>
                        ) : (
                            <span className="flex items-center gap-2">
                                <EyeOffIcon />
                                <Trans t={t} i18nKey="organizationDetailPage.editBar.organizationIsNotPublic" />
                            </span>
                        )}
                    </div>

                    <LinkButton
                        className="text-white bg-base-800 hover:bg-base-700 active:bg-base-600"
                        variant="ghost"
                        to={url(ADMIN_ORGANIZATION_PROFILE_DETAIL_PATH, {
                            profileId: organizationProfile?.id,
                        })}
                    >
                        {t('organizationDetailPage.editBar.editButton')}
                    </LinkButton>
                </EditBar>
            )}

            <div className={className} {...props}>
                <div className="flex justify-center bg-base-50 px-6 transition-colors" ref={bannerWrapperRef}>
                    <Image
                        src={organizationProfile?.banner?.url}
                        alt={organizationProfile?.name}
                        className="w-full max-w-[1120px] h-72 object-contain object-center"
                    />
                </div>

                <main className="flex justify-center bg-white px-6 py-24">
                    <div className="w-full max-w-[1120px] flex flex-col gap-32">
                        <section className="flex gap-10 -mb-6">
                            <div className="flex flex-col gap-10 w-8/12">
                                <h1 className="font-semibold text-5xl">{organizationProfile?.name}</h1>

                                <div
                                    className="styled"
                                    dangerouslySetInnerHTML={{ __html: organizationProfile?.description! }}
                                />
                            </div>

                            <div className="flex flex-col gap-12 w-4/12">
                                {(organizationProfile?.offlineLocation ||
                                    (organizationProfile?.eventDays && organizationProfile?.eventDays.length > 0)) && (
                                    <InfoBox
                                        badge={<InfoBox.Badge>{t('organizationDetailPage.onSiteBadge')}</InfoBox.Badge>}
                                    >
                                        <InfoBox.Row
                                            icon={<CalendarClockIcon />}
                                            value={
                                                organizationProfile?.eventDays &&
                                                organizationProfile?.eventDays.length > 0 && (
                                                    <span className="flex flex-col gap-2">
                                                        {organizationProfile?.eventDays?.map((day) => (
                                                            <span className="flex flex-col">
                                                                <span className="font-medium">
                                                                    {formatDate(day?.date, undefined, {
                                                                        weekday: 'long',
                                                                    })}
                                                                </span>
                                                                <span className="font-normal">
                                                                    {day?.startTime.split(':').slice(0, 2).join(':')} -{' '}
                                                                    {day?.endTime.split(':').slice(0, 2).join(':')}
                                                                </span>
                                                            </span>
                                                        ))}
                                                    </span>
                                                )
                                            }
                                        />

                                        <InfoBox.Row
                                            icon={<CompassIcon />}
                                            value={organizationProfile?.offlineLocation}
                                        />
                                    </InfoBox>
                                )}

                                <InfoBox
                                    badge={
                                        <InfoBox.Badge className="bg-base-500">
                                            {t('organizationDetailPage.detailsBadge')}
                                        </InfoBox.Badge>
                                    }
                                >
                                    <InfoBox.Row
                                        icon={<FactoryIcon />}
                                        label={t('organizationDetailPage.industry')}
                                        value={organizationProfile?.industry}
                                    />

                                    <InfoBox.Row
                                        icon={<GlobeIcon />}
                                        label={t('organizationDetailPage.locationScope')}
                                        value={organizationProfile?.locationScope}
                                    />

                                    <InfoBox.Row
                                        icon={<UsersRoundIcon />}
                                        label={t('organizationDetailPage.organizationSize')}
                                        value={organizationProfile?.organizationSize}
                                    />

                                    <InfoBox.Row
                                        icon={<RulerIcon />}
                                        label={t('organizationDetailPage.organizationType')}
                                        value={organizationProfile?.organizationType}
                                    />

                                    <InfoBox.Row
                                        icon={<LanguagesIcon />}
                                        label={t('organizationDetailPage.organizationLanguage')}
                                        value={organizationProfile?.organizationLanguage}
                                    />
                                </InfoBox>

                                <InfoBox
                                    badge={
                                        <InfoBox.Badge className="bg-base-500">
                                            {t('organizationDetailPage.onlineBadge')}
                                        </InfoBox.Badge>
                                    }
                                >
                                    <InfoBox.Row
                                        icon={<LinkIcon />}
                                        value={
                                            organizationProfile?.website && (
                                                <Anchor href={fixUrl(organizationProfile?.website)} target="_blank">
                                                    {getDomain(organizationProfile?.website)}
                                                </Anchor>
                                            )
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<LinkedinIcon />}
                                        value={
                                            organizationProfile?.linkedin && (
                                                <Anchor href={fixUrl(organizationProfile?.linkedin)} target="_blank">
                                                    {getUsername(organizationProfile?.linkedin!)}
                                                </Anchor>
                                            )
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<FacebookIcon />}
                                        value={
                                            organizationProfile?.facebook && (
                                                <Anchor href={fixUrl(organizationProfile?.facebook)} target="_blank">
                                                    {getUsername(organizationProfile?.facebook!)}
                                                </Anchor>
                                            )
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<TwitterIcon />}
                                        value={
                                            organizationProfile?.twitter && (
                                                <Anchor href={fixUrl(organizationProfile?.twitter)} target="_blank">
                                                    {getUsername(organizationProfile?.twitter!)}
                                                </Anchor>
                                            )
                                        }
                                    />

                                    <InfoBox.Row
                                        icon={<InstagramIcon />}
                                        value={
                                            organizationProfile?.instagram && (
                                                <Anchor href={fixUrl(organizationProfile?.instagram)} target="_blank">
                                                    {getUsername(organizationProfile?.instagram!)}
                                                </Anchor>
                                            )
                                        }
                                    />
                                </InfoBox>

                                <div className="rounded-xl flex flex-col gap-5 px-7">
                                    <SaveOrganizationButton
                                        organization={organizationProfile?.organization!}
                                        eventId={eventId}
                                    />

                                    {/*<span className="text-base-500 text-center">Von 346 Teilnehmern gemerkt</span>*/}
                                </div>
                            </div>
                        </section>

                        {contactPersons && contactPersons.length > 0 && (
                            <section className="flex flex-col gap-10">
                                <h2 className="font-semibold text-3xl">
                                    {t('organizationDetailPage.contactPersonsTitle')}
                                </h2>

                                <div className="grid grid-cols-4 auto-rows-auto gap-10">
                                    {contactPersons?.map((contactPerson: User) => (
                                        <ContactPersonItem key={contactPerson.id} contactPerson={contactPerson} />
                                    ))}
                                </div>
                            </section>
                        )}

                        {jobs && jobs.length > 0 && (
                            <section className="flex flex-col gap-10">
                                <h2 className="font-semibold text-3xl">{t('organizationDetailPage.jobsTitle')}</h2>

                                <div className="grid grid-cols-2 auto-rows-auto gap-10">
                                    {jobs?.map((job: Job) => <JobCard key={job.id} job={job} />)}
                                </div>
                            </section>
                        )}

                        {sessions && sessions.length > 0 && (
                            <section className="flex flex-col gap-10">
                                <h2 className="font-semibold text-3xl">{t('organizationDetailPage.sessionsTitle')}</h2>

                                <div className="flex flex-col gap-10">
                                    {sessions?.map((session: EventSession) => (
                                        <SessionCard key={session.id} session={session} />
                                    ))}
                                </div>
                            </section>
                        )}
                    </div>
                </main>
            </div>
        </>
    );
};

export default OrganizationDetailPage;
